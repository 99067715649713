import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavItem from 'react-bootstrap/NavItem'
import logo from '../../images/policydirectlogo4.jpeg'
import decode from 'jwt-decode'
import Accordion from 'react-bootstrap/Accordion';
import { BsWhatsapp } from "react-icons/bs"
import { BsFillEnvelopeAtFill } from "react-icons/bs";
import { LOGOUT } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { FaFacebook } from "react-icons/fa";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './BlinkingButton.css';
import './Tabs.css';
//import './TabContentOnHover.css'
import './TabHoverUnderline.css'
//import { Dropdown } from 'react-bootstrap';
import HoverDropdown from './HoverNavDropdown';
import HoverNavDropdown from './HoverNavDropdown';
import Dropdown from '../Header/Dropdown'
import './NavBar.css';

import allianzCareLogo from '../../images/allianzcarelogo.png'
import bupaLogo from '../../images/bupalogo.png'
import medibankLogo from '../../images/medibanklogo.png'
import nibLogo from '../../images/nibLogo.png'
//import ahmLogo from '../../images/ahmLogo.jpg'
import ahmLogo from '../../images/ahmLogo.png'


import ContactUsModal from '../Modals/ContactUsModal';
import './NavBar.css';

const LowerNavBar = ({user, setUser}) => {
  //const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  //console.log(user)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const location = useLocation();

  const logout = () => {
    dispatch({type: LOGOUT /*'LOGOUT'*/});
    navigate('/');
    setUser(null);
    //setFormData('') because if new user registers, then after autologin if he/she get logout,then his sign up page is filled with his details
    
  }

  /* useEffect(() => {
    const token = user?.token;
    
    //JWT...later for the manual sign up 
    if(token) {
      const decodedToken = decode(token);
      if(decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]); */

  const handleHoverFaq = () => {
    console.log()

  }

  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  //
  const [activeTab, setActiveTab] = useState(null); // Set the initial active tab

  const handleTabSelect = (eventKey) => {
    if (activeTab === eventKey) {
      setActiveTab(null); // Toggle off if the same tab is clicked again
    } else {
      setActiveTab(eventKey); // Toggle on for a different tab
    }
  };

  //

  //
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  //
  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //

  return (
    <>
   
   <Tab.Container id="tabs" /* defaultActiveKey="oshc" */ justify activeKey={activeTab} onSelect={handleTabSelect}>
 
      {/* Stack the columns on mobile by making one full-width and the other half-width */}
      <Row>
          <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Home Insurance</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Coming Soon...</Card.Subtitle>
              <Card.Text>
              Home Insurance is coming soon...
            </Card.Text>
            <Button variant="primary" style={{background: '#3B5998' }}>Get Home Insurance</Button>
            {/* <Card.Link href="#">Get Car Insurance</Card.Link> */}
            
          </Card.Body>
        </Card>
        </Col>

        <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Car Insurance</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Coming Soon...</Card.Subtitle>
              <Card.Text>
              Car Insurance is coming soon...
            </Card.Text>
            <Button variant="primary" style={{background: '#3B5998' }}>Get Car Insurance</Button>
            {/* <Card.Link href="#">Get Car Insurance</Card.Link> */}
            
          </Card.Body>
        </Card>
        </Col>

        <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Building Insurance</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Coming Soon...</Card.Subtitle>
              <Card.Text>
              Building Insurance is coming soon...
            </Card.Text>
            <Button variant="primary" style={{background: '#3B5998' }}>Get Building Insurance</Button>
            {/* <Card.Link href="#">Get Car Insurance</Card.Link> */}
            
          </Card.Body>
        </Card>
        </Col>

        <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Pet Insurance</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Coming Soon...</Card.Subtitle>
              <Card.Text>
              Pet Insurance is coming soon...
            </Card.Text>
            <Button variant="primary" style={{background: '#3B5998' }}>Get Pet Insurance</Button>
            {/* <Card.Link href="#">Get Car Insurance</Card.Link> */}
            
          </Card.Body>
        </Card>
        </Col> 
      </Row>  

      <Row>
          <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Overseas Student Health Cover (OSHC)</Card.Title>              
              <Card.Text>              
                <Nav.Link eventKey="oshc" /* className="tab-item" */ >                 
                <Button variant="primary" style={{background: '#3B5998'}}>Get Price</Button>                  
                </Nav.Link>              
            </Card.Text>           
          </Card.Body>
          </Card>
          </Col>

          <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Overseas Visitor Health Cover (OVHC) </Card.Title>              
              <Card.Text>              
                <Nav.Link eventKey="ovhc" /* className="tab-item" */ >                 
                <Button variant="primary" style={{background: '#3B5998'}}>Get Price</Button>                  
                </Nav.Link>              
            </Card.Text>           
          </Card.Body>
          </Card>
          </Col>

          <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Travel Insurance</Card.Title>              
              <Card.Text>              
                <Nav.Link /* className="tab-item" */ href='/travelinsurance'>                 
                <Button variant="primary" style={{background: '#3B5998'}}>Get Price</Button>                  
                </Nav.Link>              
            </Card.Text>           
          </Card.Body>
          </Card>
          </Col>

          <Col xs={12} sm={6} md={3} lg={3} style={{padding: "none"}}>
          <Card style={{ width: '100%', color: '#3B5998', margin: 'none' }}>
            <Card.Body>
              <Card.Title>Bike Insurance</Card.Title>              
              <Card.Text>              
                <Nav.Link  /* className="tab-item" */ >  
                Coming Soon...
                <br/>               
                <Button variant="primary" style={{background: '#3B5998'}}>Get Price</Button>                  
                </Nav.Link>              
            </Card.Text>           
          </Card.Body>
          </Card>
          </Col>
          <br/><br/><br/>
      <Tab.Content >
      {activeTab === 'oshc' && <>
        <Tab.Pane eventKey="oshc" className='tab-content'>
          {/* Content for Tab 1 */}
          <Container>
        <Row>
        <Col sm={2}>
        
        <a /* style={{marginRight: '1em', marginBottom: '1em'}} */ href='https://api.allianz.com/myquote/1?groupId=84692'>          
                    <img
                          src={allianzCareLogo}
                          width="98px"
                          height="40px"
                          style={{borderRadius: "10px", zIndex: '1', marginBottom: '1em' /* boxShadow: '2px 2px 2px' */}}
                      />
                    </a>
        </Col>
        <Col sm={3}>
        {/* <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1px 1px 1px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
        <a className="tab-item" href='/contactus' style={{textDecoration: 'none', color: 'white'}}><strong>Bupa</strong></a>
       </Button> */}
                  
                    <img
                          className='logo-images'
                          onClick={openContactModal}
                          src={bupaLogo}
                          width="98px"
                          height="40px"
                          style={{borderRadius: "10px", zIndex: '1', marginBottom: '1em' /* boxShadow: '2px 2px 2px' */}}
                      />
                     
        </Col>
        <Col sm={2}>
       {/*  <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1px 1px 1px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
        <a className="tab-item" href='/contactus' style={{textDecoration: 'none', color: 'white'}}><strong>Medibank</strong></a>
      </Button> */}
               
                    <img
                          className='logo-images'
                          onClick={openContactModal}
                          src={medibankLogo}
                          width="98px"
                          height="40px"
                          style={{borderRadius: "10px", zIndex: '1', marginBottom: '1em' /* boxShadow: '2px 2px 2px' */}}
                      />
                     
        </Col>
        <Col sm={3}>
        {/* <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1px 1px 1px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
        <a className="tab-item" href='/contactus' style={{textDecoration: 'none', color: 'white'}}><strong>NIB</strong></a>
        </Button> */}
                  
                    <img
                          className='logo-images'
                          onClick={openContactModal}
                          src={nibLogo}
                          width="98px"
                          height="40px"
                          style={{borderRadius: "10px", zIndex: '1', marginBottom: '1em' /* boxShadow: '2px 2px 2px' */}}
                      />
                    
        </Col>
        <Col sm={2}>
        {/* <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1px 1px 1px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
        <a className="tab-item" href='/contactus' style={{textDecoration: 'none', color: 'white'}}><strong>AHM</strong></a>
        </Button> */}
                  
                    <img
                          className='logo-images'
                          onClick={openContactModal}
                          src={ahmLogo}
                          width="98px"
                          height="40px"
                          style={{borderRadius: "10px", zIndex: '1', marginBottom: '1em' /* boxShadow: '2px 2px 2px' */}}
                      />
                    
        </Col>      
        </Row>        
        </Container>
        </Tab.Pane>
        </>}
        {activeTab === 'ovhc' && <>
        <Tab.Pane eventKey="ovhc">
          {/* Content for Tab 2 */}
       {/*  <div>
          create a background image
        </div> */}
          <Container>
        <Row fill>
        {/* <Col sm={4}>FAQS</Col> */}
          <Col sm={4} >
            <div className='cover-options-button-columns'>
            <a href='/visitorvisacoveroptions' style={{textDecoration: 'none', color: 'white'}}>
            <strong >
            Visitor Visa Subclass<br/>
            (600, 601, 651, 300)
            </strong>
            </a>
            </div>            
          
          {/* <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1.5px 1.5px 1.5px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
            <a className="tab-item" href='/visitorvisacoveroptions' style={{color: 'white', marginBottom: '.5em', fontSize: '1em'}}>
                <strong>Visitor Visa Subclass<br/>
                 (600, 601, 651, 300)
                 </strong>                
            </a>
          </Button> */}
          </Col>
          <Col sm={4}>
          <div className='cover-options-button-columns'>
          <a href='/workingvisacoveroptions' style={{textDecoration: 'none', color: 'white'}}>
          <strong>Working Visa Subclass<br/>
                (188, 407, 408, 417, 
                461, 462, 476, 482, 485, 491, 494)
          </strong> 
          </a>
          </div>
          {/* <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1.5px 1.5px 1.5px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
            <a className="tab-item" href='/workingvisacoveroptions' style={{color: 'white', marginBottom: '.5em', fontSize: '1em'}}>
                <strong>Working Visa Subclass<br/>
                (188, 407, 408, 417, 
                461, 462, 476, 482, 485, 491, 494)
                 </strong>                
            </a>
          </Button>      */}   
          </Col>
          <Col sm={4}>
          <div className='cover-options-button-columns'>
          <a href='/nonworkingvisacoveroptions' style={{textDecoration: 'none', color: 'white'}}>
            <strong>Non Working Visa Subclass<br/>
                (BVA, BVB, BVC, 590, 870)
            </strong>
          </a>
          </div>
         
          </Col>         
        </Row>        
        </Container>
        </Tab.Pane>
        </>}
      {/* Add more panes if needed */}
      </Tab.Content> 

      </Row>   
      </Tab.Container>  
     


{/*delete below this */}


    <hr/>
    <Container className='faq-container'>
      <Row>
        <Col>
        <strong>
              <a href="/faqs" className="tab-item" style={{color: 'black', marginBottom: '.5em', fontSize: '.9em'}}>FAQS</a>
              <hr/>
        </strong>

        </Col>
      </Row>
    </Container>

    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />
    
    </>
  )
}

export default LowerNavBar


